import ApiService from "./api.service";

const RegionService = {
  GetAll() {
    return ApiService.get(`/Region/GetALL`);
  },
  GetRegionList(OblastID, lang) {
    return ApiService.get(
      `/Region/GetRegionList?OblastID=${OblastID}&lang=${lang}`
    );
  },
};
export default RegionService;
