import ApiService from "./api.service";
const HelperService = {
  GetAllSchools(search, SortColumn, OrderType, PageNumber, PageLimit) {
    return ApiService.get(
      `/Helper/GetAllSchools?search=${search}&SortColumn=${SortColumn}&OrderType=${OrderType}&PageLimit=${PageLimit}&PageNumber=${PageNumber}`
    );
  },
  GetOrgAndPersonCount() {
    return ApiService.get(`/Helper/GetOrgAndPersonCount`);
  },
  GetAllOblastSchools(
    oblastid,
    search,
    SortColumn,
    OrderType,
    PageNumber,
    PageLimit
  ) {
    return ApiService.get(
      `/Helper/GetAllOblastSchools?oblastid=${oblastid}&search=${search}&SortColumn=${SortColumn}&OrderType=${OrderType}&PageLimit=${PageLimit}&PageNumber=${PageNumber}`
    );
  },
  GetAllRegionSchools(
    oblastid,
    regionid,
    search,
    SortColumn,
    OrderType,
    PageNumber,
    PageLimit,lang
  ) {
    return ApiService.get(
      `/Helper/GetAllRegionSchools?oblastid=${oblastid}&regionid=${regionid}&search=${search}&SortColumn=${SortColumn}&OrderType=${OrderType}&PageLimit=${PageLimit}&PageNumber=${PageNumber}&lang=${lang}`
    );
  },
  GetOrganization(
    oblastid,
    regionid,
    organizationid,
    search,
    SortColumn,
    OrderType,
    PageNumber,
    PageLimit,lang
  ) {
    return ApiService.get(
      `/Helper/GetOrganization?oblastid=${oblastid}&regionid=${regionid}&organizationid=${organizationid}&search=${search}&SortColumn=${SortColumn}&OrderType=${OrderType}&PageLimit=${PageLimit}&PageNumber=${PageNumber}&lang=${lang}`
    );
  },
};
export default HelperService;
